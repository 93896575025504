export type IType = 1 | 2 | 3  // 1 - 年历  2 - 月历 3 - 日历
type IStatus = 0 | 1 | 2 // 0 - 不可用 1 - 可选 2 - 已选
export interface ICell{
    full: string,
    value: number
    label: number | string
    status: IStatus
}
export const gap: number = 10  //年历 十年 分割
const total: number = 7 * 6 // 日历列表最多呈现6排
// 格式化数据
export const formatDate = (num: number) => num > 9 && num || `0${num}`
// 周
export const weekEnu: string[] = ['一','二','三','四','五','六','日']
// 判断平闰年
export const runNian = (_year: number) => {
    if(_year%400 === 0 || (_year%4 === 0 && _year%100 !== 0) ) {
        return true;
    }
    else { return false; }
}

// 判断每月多少天
const enuDays = (params: { _year: number,_month: number }) => {
    const {_year,_month} = params
    let day: number = 0;
    switch (_month){
        case 1:
            day = 31;
            break;
        case 2:
            if(runNian(_year)) { day += 29; }
            else { day += 28; }
            break;
        case 3: day += 31; break;
        case 4: day += 30; break;
        case 5: day += 31; break;
        case 6: day += 30; break;
        case 7: day += 31; break;
        case 8: day += 31; break;
        case 9: day += 30; break;
        case 10: day += 31; break;
        case 11: day += 30; break;
        case 12: day += 31; break;
    }
    return day
}
//判断某年某月的1号是星期几
export const getFirstDay = (_year: number,_month: number) => {
    let allDay = 0, y = _year-1, i = 1;
    allDay = y + Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400) + 1;
    for ( ; i<_month; i++) {
        allDay += enuDays({ _year, _month: i })
    }
    return allDay%7;
}

// 获取合法年月
export const getYear = (params?: { _year?: number,_month?: number }) => {
    let y: number = params?._year?? new Date().getFullYear();
    let m: number = params?._month?? new Date().getMonth() + 1
    if(m>12){
        m = 1;
        y++;
    }
    if(m<1){
        m = 12;
        y--;
    }
    return {
        year: y,
        month: m
    }
}

// 获取日历列表
export const getMonthDay = (params?: { _year?: number,_month?: number }) => {
    const _year = getYear(params)?.year
    const _month = getYear(params)?.month
    const _preMonth = _month - 1 || 12;

    const _preYear = _month - 1 ? _year : _year -1
    const cells: ICell[] = [];
    const days:number=enuDays({ _year, _month })//当前月天数
    const preDays: number = enuDays({ _year:_preYear, _month:_preMonth })//上个月天数
    for ( let i = 1; i <= days; i ++ ) { //当前月日历
        cells.push({
            full: `${_year}-${formatDate(_month)}-${formatDate(i)}`,
            value: i,
            label: i,
            status: 1
        })
    }
    for (let i = preDays;i>preDays-getFirstDay(_year,_month)+1;i--){ // 表头填充上个月尾
        cells.unshift(
            {
                full: `${getYear({_year,_month:_month-1})?.year}-${formatDate(getYear({_year,_month:_month-1})?.month)}-${formatDate(i)}`,
                value: i,
                label: i,
                status: 0
            }
        )
    }
    const len = total - cells.length
    for (let i = 1;i<=len;i++ ) { // 日历尾部下个月
        cells.push({
            full: `${getYear({_year,_month:_month+1})?.year}-${formatDate(getYear({_year,_month:_month+1})?.month)}-${formatDate(i)}`,
            value: i,
            label: i,
            status: 0
        })
    }
    return cells
}
// 获取月历
export const getMonthCells = (params:{_year: number}) => {
    const {_year} = params
    let cells:ICell[] = []
    for (let i = 1;i<=12;i++){
        cells.push({
            full: `${_year}-${formatDate(i)}`,
            value: i,
            label: `${i}月`,
            status: 1
        })
    }
    return cells
}

// 获取年历
export const getYearCells = (params:{_year: number}) => {
    const {_year} = params
    let cells:ICell[] = []
    const y: number = Math.floor(_year / gap) * 10
    // 当前十年
    for (let i = 0;i<gap;i++){
        cells.push({
            full: `${y+i}年`,
            value: y+i,
            label: y+i,
            status: 1
        })
    }
    cells.unshift({
        full: `${y-1}年`,
        value: y-1,
        label: y-1,
        status: 0
    })
    cells.push({
        full: `${y+10}年`,
        value: y+10,
        label: y+10,
        status: 0
    })
    return cells
}
// 获取cell数据
export const getCells = (params?: { _year?: number,  _month?: number, type?: IType}) => {
    const type: number = params?.type?? 3
    const _year = getYear(params)?.year
    return type === 1 && getYearCells({_year}) || type === 2 && getMonthCells({_year}) || getMonthDay(params)
}
// 年/月/日历 不同排版样式
export const getClassName = (params:{type: IType,status: IStatus}) => {
    const {type,status} = params
    let classNames: string[] = []
    switch (type){
        case 1:
            classNames.push('zc-picker-cell-month',status && 'zc-picker-cell-available' || 'zc-picker-cell-disable')
            break;
        case 2:
            classNames.push('zc-picker-cell-month','zc-picker-cell-available')
            break;
        case 3:
            classNames.push('zc-picker-cell',status && 'zc-picker-cell-available' || 'zc-picker-cell-disable')
            break;
    }
    return classNames.join(' ')
}
// 获取picker head内容
export const getPickerTitle = (params: {type:IType,_year: number}) => {
    const {type,_year} = params
    const y: number = Math.floor(_year / gap) * 10
    return type === 1 && `${y} - ${y+ gap}` || type === 2 && `${_year}年` || `${_year}年`
}
//获取反填日期
export const getDefaultValue = (e?:any) => {
    const date = e && new Date(e) || new Date()
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1
    }
}





