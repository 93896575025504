import {ISelect} from "@/components/Select/helper";
import {isParentDom} from "@/components/ZCUtil";
import React, {useEffect, useRef, useState} from "react";
import {IFormItemProps} from "../FormItemEx/interface";
import './index.less';


type borderedType = 'all' | 'bottom' | 'none'

const borderedMap = {
    'all': 'zc-select-input-wrap',
    'bottom': 'zc-select-input-bottom-wrap',
    'none': 'zc-input-none'
}
// const activeMap = {
//     'all': 'zc-area-input-wrap-active',
//     'bottom': 'zc-area-input-bottom-wrap-active',
//     'none': 'input-bottom-active'
// }

interface Props extends IFormItemProps {
    display?: 'grid' | 'line'
    value?: any
    onChange?: any
    bordered?: borderedType
}

const ZSelect = (props: Props) => {
    const {items, placeholder, fld, disabled, width = 'auto', size, bordered = 'all'} = props;
    const [visible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isArrowHover, setIsArrowHover] = useState(false);
    const containerRef = useRef<any>()
    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (!isParentDom(e.target, containerRef.current)) {
                setVisible(false)
            }
        })
    }, [])


    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const sizeClass = {
        wrap: `${borderedMap[bordered]} ${visible ? `zc-select-input-wrap-active` : ''} zc-select-wrap-height-${size}`,
        optionWrap: `${props.display == 'grid' ? 'zc-select-grid-option-wrap' : 'zc-select-option-wrap'} ${!visible ? 'zc-select-option-wrap-hide' : ''} zc-select-option-top-${size}`,
        inputWrap: `zc-select-input zc-select-wrap-height-${size}`,
    }
    const onSelectChange = (item: any) => {
        setInputValue(item.label);
        props?.onChange(item?.value)
        setVisible(false)
    }

    const onClick = () => {
        if(disabled){
            return
        }
        setVisible(true)
    }

    const onClear = () => {
        if (disabled) {
            return
        }
        setInputValue('');
        props?.onChange('')
    }
    return (
        <div className="zc-select-container" ref={containerRef} style={{width}} >
            <div className={sizeClass.wrap} onClick={onClick} onMouseEnter={()=>!disabled && setIsArrowHover(true)} style={{background:  disabled &&'#eee' || ''}} onMouseLeave={()=>setIsArrowHover(false)}>
                <input className={sizeClass.inputWrap} placeholder={placeholder} style={{color: disabled && '#999' || ''}} value={inputValue||''} readOnly name={fld} autoComplete="off"/>
                <div className="zc-select-right" onClick={onClear}>
                    {!(isArrowHover&&inputValue)&&<i className={visible?"iconfont icon-jiantou-shang":"iconfont icon-jiantou-xia"}/>}
                    {(isArrowHover&&inputValue) && !disabled&&<i className="clear-icon iconfont icon-guanbi"/>}
                </div>
            </div>
            <div className={sizeClass.optionWrap}>
                {
                    items?.map((item: ISelect, index: number) =>
                        <div key={index}
                             className={`zc-select-option pointer ${inputValue === item.label && 'zc-select-active' || ''}`}
                             onClick={() => onSelectChange(item)}>{item.label}</div>
                    )
                }
            </div>
        </div>
    )
}

export default ZSelect;