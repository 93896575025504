export  const regRules = {
    hsCode: {
        reg: /^[0-9\s]{1,20}$/,
        message: '请输入海关编码'
    },
    password: {
        reg: /^(?=.*[A-Za-z])[A-Za-z\d]{6,}$/,
        message: '请输入6位以上带英文的密码'
    },
    integer: {
        reg: /^[1-9]\d*$/,
        message: 'app.form.valid.digit'
    },// 整数
    digit: {
        reg: /^-?[1-9]*(.d*)?$|^-?0(.d*)?$/,
        message: 'app.form.valid.digit'
    },//数字
    number: {
        reg: /^\+?[1-9][0-9]*/,
        message: '请输入数字'
    },//正数数字
    money: {
        reg: /^-?((^[1-9]\d*)|^0)(\.\d{0,12}){0,1}$/,
        message: '请输入正确的正数数字'
    },//金额
    price: {
        reg: /^((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/,
        message: '请输入正确的金额'
    },//正数金额
    decimal: {
        reg: /^/,
        message: 'app.form.valid.decimal'
    },//小数
    email: {
        reg: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/,
        message: '请输入正确的邮箱'
    },//邮箱
    ip: {
        reg: /^$/,
        message: 'app.form.valid.ip'
    },//ip
    en:{
        reg: /^[a-zA-Z0-9\-\s]*$/,
        message: '请输入英文'
    },//纯英文
    nocn: {
        reg: /^[^\u4e00-\u9fa5]+\s*$/,
        message: '请输入英文'
    },
    username: {
        reg: /^[a-zA-Z0-9_-]+$/,
        message: '请输入正确的用户名'
    },
    phone: {
        reg: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
        message: '请输入正确的手机号'
    },
    required: {
        reg: /^[\s\S]*.*[^\s][\s\S]*$/,
    }
}
  
  