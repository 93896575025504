import { regRules } from '@/utils/Rules';

export const checkAllFldValid = (validData: any, validMaps: any[]) => {
    const result = Object.keys(validMaps).reduce((total: any, key: any) => {
        let isValid = false;
        const item = validMaps[key];
        if (item.required) {
            if (item.valid) {
                isValid = regRules[item.valid].reg.test(validData[key]);
            } else {
                isValid = validData[key]
            }
        } else {
            if (item.valid) {
                isValid = regRules[item.valid].reg.test(validData[key]);
            } else {
                isValid = true;
            }
        }
        total[key] = isValid;
        return total;
    }, {});
    return Object.values(result).every(item=>item)
}

// 去除空参数
export const formatParams = (params: any) => {
    return  Object.keys(params).reduce((result: any,item: string) => {
        if(isTrue(params[item])){
            result[item] = params[item]
        }
        return result
    },{})
}

// 判空  null undefined ''
const isTrue = (params: any) => {
    if(params === null || params === undefined || params === ''){
        return false
    } else {
        return true
    }
}