import {getRandomKey} from "@/utils/ZUtils";
import React, {useEffect, useReducer} from "react";
import {checkAllFldValid, formatParams} from "./helper";
import './index.css';

export interface LabelInputProps {
    children?: any,                      //  国际化key
    childwidth?: any,                    //  组件宽度
    labelwidth?: any                     //  label宽度
    break?: boolean
    initialValues?: any,
    className?: any,
    onFinish?: any,
    onValuesChange?: any,
    labelStyle?:any                  // float / innear / top   || 默认top
    style?:any
    onSearch?:(currentSearch:any,totalSearch:any)=>void
    onBlur?: any
    validAlign?: 'left' | 'center' | 'right'
}
export const FormExContext = React.createContext({});
export const EVENT = {
    ONFORMVALUECHANGE:1,      // 表单修改
    DOVALID:2,                // 主动触发校验
    ADDVALIDTYPE:4,           // 添加校验类型
    DELETEVALIDTYPE:5,        // 删除校验类型
    RESET:9,                  // 表单重置
    ONSEARCH:10               // 表单触发搜索
}


const reducer = (state: any,action: any) => {
    switch (action.type) {
        // 添加请求参数：添加参数，设置分页为第一页 适用于请求参数改变的情况
        case EVENT.ONFORMVALUECHANGE: // 表单change
            return {...state, initialValues: action.initialValues,active: {...action.active}};
        // 表单重置
        case EVENT.RESET:
            return {...state, initialValues: {},active: {}};
        case EVENT.DOVALID:
            // 主动触发表单校验
            return {...state,submitTrigger:action?.submitTrigger};
        case EVENT.ADDVALIDTYPE:
            // 添加校验类型
            return {...state,validTypes:{...state.validTypes,...action?.validTypes}};
        case EVENT.DELETEVALIDTYPE:
            // 删除校验类型
            return {...state,validTypes:action?.validTypes};
        case EVENT.ONSEARCH:
            // 触发搜索 select 组件的 onSelect  input的onBlur等
            return {...state,searchValue:{...state.searchValue,...action?.searchValue}};
        default:
            return state;
    }
}

const FormEx = (props:LabelInputProps) => {
    const {className} = props
    const [state, dispatch] = useReducer(reducer, {
        active:{},//当前活动项
        initialValues: props?.initialValues??{},
        childrenwidth: props.childwidth,
        validAlign: props?.validAlign?? 'left',
        labelwidth: props.labelwidth,
        break: props.break,
        labelStyle:props.labelStyle,
        submitTrigger:0,
        validFlds:{},
        validTypes:{}
    });




    useEffect(() => {
        if (Object.values(state.active)?.length) {
            props?.onBlur?.(state.active)
        }
        if (state.searchValue && props.onSearch) {
            props?.onSearch(state?.initialValues, state.searchValue)
        }
    },[state.searchValue])




    useEffect(() => {
        if(Object.keys(props?.initialValues??{}).length <= Object.keys(state?.initialValues).length){
            const fields: string[] = Object.keys(props?.initialValues??{})
            const validArr: string[] = Object.keys(state.validTypes)
            if(validArr.length){
                const json = fields.reduce((result: any,item: string) => {
                    if(validArr.includes(item)){
                        result[item] =  state.validTypes[item]
                    }
                    return result
                },{})
                dispatch({
                    type: EVENT.DELETEVALIDTYPE,
                    validTypes: json
                })
            }
        }
        if(props?.initialValues){
            dispatch({
                type: EVENT.ONFORMVALUECHANGE,
                ...state,
                initialValues: {
                    ...props?.initialValues
                }
            })
        }
    },[props?.initialValues])



    const onSubmit = (e: any) => {
        e.preventDefault()
        e.stopPropagation();
        dispatch({
            type: EVENT.DOVALID,
            ...state,
            submitTrigger: getRandomKey()
        })
        if(checkAllFldValid(state?.initialValues ,state?.validTypes)){

            props.onFinish(formatParams(state.initialValues));
        }
    }
    // 表单重置
    const onReset = () => {
        dispatch({
            type:EVENT.RESET,
        })
        if(props.onSearch){
            props.onSearch({},{})
        }
    }


    useEffect(() => {
        if(state?.active){
            if(props?.onValuesChange && JSON.stringify(state?.active) != '{}'){
                props?.onValuesChange(state.active,state.initialValues)
            }
        }

    },[state.initialValues])

    return (
        <FormExContext.Provider value={{state, dispatch}}>
            <form autoComplete="off" className={`formExWrap ${className}`} style={props.style} onSubmit={onSubmit} onReset={onReset}>
                {props.children}
            </form>
        </FormExContext.Provider>
    )
};

export default FormEx;
