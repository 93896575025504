import { EVENT, FormExContext } from "@/components/FormEx";
import { regRules } from "@/utils/Rules";
import React, { useContext, useEffect, useState } from "react";
import ZPicker from "@/components/DatePicker";
import ZSelect from "../Select";
import ZSelectArea from "../SelectArea";
import ZInput from "./Input";
import ZUpload from "@/components/Upload";
import ZRadio from "@/components/Radio";
import ZTextArea from "../TextArea";
import FbaWareSelect from "@/components/FbaWareSelect";
import './index.less';
import {IFormItemProps} from "./interface";

const wrapperMap = {
    'default': 'zform-item-content-wrap',
    'table': 'z-form-item-table'
}

export const FormItemEx = (props: IFormItemProps) => {
    const [errorMsg, setErrorMsg] = useState<any>('');
    const getWidth = () => width ? (width - (labelwidth || 0)) : undefined
    const {
        extra = {},
        type = 'input',
        width,
        labelwidth,
        labelAlign = 'right',
        fld = '',
        hideChart = false,
        hideLabel = false,
        label,
        size,
        items,
        suffix,
        required,
        valid,
        validMessage,
        upload,
        // validAlign = 'left',
        labelStyle = {},
        vertical = 'center',
        filter = false,
        align = 'left',
        mode = 'default'
    } = props;

    //@ts-ignore
    const { state, dispatch } = useContext(FormExContext);

    const doValid = (e: any) => {
        if (valid) {
            if (!required && !e) {
                setErrorMsg(undefined);
                return;
            }
            if (!regRules[valid].reg.test(e)) {
                setErrorMsg(validMessage || regRules[valid].message);
            } else {
                setErrorMsg(undefined);
            }
        } else {
            if (required && !valid) {
                if (!e) {
                    setErrorMsg(validMessage || `请输入${label}`);
                } else {
                    setErrorMsg(undefined);
                }
            }
        }
    }

    // onchange 事件
    const onChange = (e: any) => {
        if (errorMsg != '') {
            doValid(e)
        }
        if (!e && !required) {
            setErrorMsg(undefined)
        }
        props?.onChange?.(e)
        dispatch({
            type: EVENT.ONFORMVALUECHANGE,
            initialValues: {
                ...state.initialValues,
                ...{[fld]: e}
            },
            active: {[fld]: e}
        })
        // onChange需要触发 onSearch的组件类型
        if(['select','area','picker'].includes(type)){
            dispatch({
                type: EVENT.ONSEARCH,
                initialValues: {
                    ...state.initialValues,
                    ...{[fld]: e}
                },
                searchValue: {
                    ...{[fld]: state.initialValues[fld]}
                }
            })
        }
    }

    useEffect(() => {
        onAddValidTypes({required, valid})
    }, [required, valid])

    const onAddValidTypes = (type:any) => {
        dispatch({
            type: EVENT.ADDVALIDTYPE,
            validTypes: {...state?.validTypes, ...{[fld]: type}}
        })
    }
    useEffect(() => {
        if (state?.submitTrigger) {
            doValid(state.initialValues[fld] || "")
        }
    }, [state?.submitTrigger]);
    const onBlur = () => {
        doValid(state.initialValues[fld] || "");
        dispatch({
            type: EVENT.ONSEARCH,
            searchValue: {
                ...{[fld]: state.initialValues[fld]}
            }
        })
    }

    const commonProps = {
        align,
        ...props,
        width: getWidth(),
        labelAlign,
        onChange,
        onBlur,
        errorMsg,
        mode,
        value: state?.initialValues?.[fld as string]
    }
    const compFactory = (
        <>
            {type == 'input' && <ZInput type='input' {...commonProps} {...extra} />}
            {type == 'password' && <ZInput type='password'  {...commonProps} />}
            {type == 'number' && <ZInput type='number' {...commonProps} />}
            {type == 'select' && <ZSelect  {...commonProps} {...extra}
                                           value={items.find((i: any) => i.value === (fld && state?.initialValues[fld]))?.label}/>}
            {type == 'area' && <ZSelectArea {...commonProps} {...extra} />}
            {type == 'picker' && <ZPicker  {...commonProps} />}
            {type == 'upload' && <ZUpload  {...commonProps} upload={upload}/>}
            {type == 'textarea' && <ZTextArea  {...commonProps} />}
            {type == 'radio' && <ZRadio {...commonProps} />}
            {type == 'fba' &&
            <FbaWareSelect fld={fld} onVal={onChange} value={state?.initialValues?.[fld as string]} {...extra} />}
        </>
    )

    return (
        <div className={`${wrapperMap[mode]} ${filter && 'mb10' || ''}`} style={{width, alignItems: vertical}}>
            {!hideLabel && <div style={{
                width: labelwidth,
                minWidth: labelwidth,
                textAlign: labelAlign,
                fontSize: size === 'mini' ? '13px' : '',
                ...labelStyle
            }}>
                {!hideLabel && label}
                {!hideChart && '：'}
            </div> || ''}
            {compFactory}
            {suffix && <span className='z-form-suffix-wrapper'>{suffix ?? ''}</span>}
        </div>
    )

}


