import React from "react";
import './index.less'
import {ISelect} from "@/components/Select/helper";

export interface RadioProps {
    value?: string | number | boolean
    onChange?: <T>(P: T) => void
    items?: ISelect[]
}


const ZRadio = (props: RadioProps) => {
    const {items = [], onChange, value} = props
    return (
        <div className='flex align-items-center'>
            {items && items.map((i: ISelect, index: number) =>
                <div key={index} className='flex align-items-center mr30 cursor-point'
                     onClick={() => onChange?.(i.value)}>
                    {value == i.value && <div className='dot-selected'><span/></div> || <div className='dot-default'/>}
                    <span style={{whiteSpace: 'nowrap'}}>{i.label}</span>
                </div>
            )}
        </div>
    )
}

export default ZRadio