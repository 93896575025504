import React, {useEffect, useRef, useState} from "react";
import './index.less'
import {ICell,weekEnu,getYear,getCells,IType,getClassName,gap,getPickerTitle,getDefaultValue} from "./helper";
import ZInput from "@/components/FormItemEx/Input";
import {isParentDom} from "@/components/ZCUtil";
import {IFomItemSize} from "@/components/FormItemEx/interface";


interface Props{
    placeholder?:string
    width?:number
    size?: IFomItemSize
    fld?: string
    onChange?: any
    value?: any
}

const ZPicker = (props: Props) => {
    const [cells,setCells] = useState<ICell[]>([])
    const [type,setType] = useState<IType>(3) // 1 - 年份 2 - 月份 3 - 日期
    const [start, setStart] = useState<string>('')
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
    const [visible, setVisible] = useState(false)
    const [isArrowHover, setIsArrowHover] = useState(false);
    const containerRef = useRef<any>();
    const {
        size,
        fld,
        placeholder,
        value,
    } = props

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            if (!isParentDom(e.target, containerRef.current)) {
                setVisible(false)
            }
        })
    }, [])


    // 选中
    const onSelect = (i: ICell) => {
        switch (type) {
            case 1:  // 年历选择
                setYear(i.value)
                setType(2)
                break;
            case 2: // 月历选择
                setMonth(i.value)
                setType(3)
                break;
            case 3:  // 日历选择
                setStart(i.full)
                fld && props.onChange(i.full)
                setTimeout(() => {
                    setVisible(false)
                },90)
                break
        }
    }
    useEffect(() => {
        setYear(getDefaultValue(start)?.year)
        setMonth(getDefaultValue(start)?.month)
    },[start])
    useEffect(() => {
        setCells(getCells({_year: year,_month:month,type}))
    },[year,month,type])
    // 改变年份
    const changeYear = (num: number) => {
        let y: number = year
        y += num * (type === 3 && 1 || gap)
        setYear(y)
    }
    // 改变月份
    const changeMonth = (num: number) => {
        let m: number = month
        m += num
        setMonth(getYear({_year:year,_month:m})?.month)
        setYear(getYear({_year:year,_month:m})?.year)
    }
    const onFocus = (e: any) => {
        setStart(e)
        setVisible(true)
    }
    const onChange = (e: any) => {

        // console.log(e)
    }

    useEffect(() => {
        setStart(value)
    }, [value])

    const onClear = () => {
        setStart('')
        fld && props.onChange('')
    }
    return (
        <div className='zc-picker'
             ref={containerRef}
             onMouseEnter={() => setIsArrowHover(true)}
             onMouseLeave={() => setIsArrowHover(false)}
             onClick={(e: any) => {
                 e.preventDefault();
                 e.stopPropagation()
             }}>
            <ZInput onFocus={onFocus} readOnly value={start} fld={fld} placeholder={placeholder} width={props?.width}
                    type='picker' size={size} onChange={onChange}/>
            <div className='picker-right-icons' onClick={onClear}>
                {!(isArrowHover && start) &&
                <i className={visible ? "iconfont icon-jiantou-shang" : "iconfont icon-jiantou-xia"}/>}
                {(isArrowHover && start) && <i className="clear-icon iconfont icon-guanbi"/>}
            </div>

            <div className={`zc-picker-wrapper ${visible && 'zc-picker-show' || 'zc-picker-hide'}`}>
                <div className='zc-picker-panel'>
                    <div className='zc-picker-head flex align-items-center justify-between'>
                        <button onClick={() => changeYear(-1)}> &lt;&lt; </button>
                        {type === 3 && <button onClick={() => changeMonth(-1)}>&lt;</button> || ''}
                        <div className='flex1 text-center'>
                            <button onClick={() => setType(1)}> {getPickerTitle({type, _year: year})} </button>
                            {type === 3 && <button onClick={() => setType(2)}> {month}月 </button> || ''}
                        </div>
                        {type === 3 && <button onClick={() => changeMonth(1)}>&gt;</button> || ''}
                        <button onClick={() => changeYear(1)}> &gt;&gt; </button>
                    </div>

                    <div className='zc-picker-body'>
                        {type === 3 && <div className='zc-picker-body-week'>
                            {weekEnu && weekEnu.map((i: string) => <div className='zc-picker-cell' key={i}>{i}</div>)}
                        </div> || ''}
                        <div className='zc-picker-body-content'>
                            {cells && cells.map((i: ICell,index: number) =>
                                <div key={index} title={i.full} onClick={ () => onSelect(i)}
                                     className={`${getClassName({type,status:i.status})} ${start === i.full && 'zc-picker-cell-active' || ''}`}>{i.label}</div>)}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ZPicker